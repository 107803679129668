<template>
  <vue-advanced-chat
    :current-user-id="currentUserId"
    :rooms="JSON.stringify(rooms)"
    :messages="JSON.stringify(messages)"
    :rooms-loaded="true"
    show-audio="false"
    show-search="false"
    show-add-room="false"
    show-files="false"
    show-emojis="false"
    show-reaction-emojis="false"
    media-preview-enabled="false"
    :messages-loaded="messagesLoaded"
    message-actions="[]"
    :text-messages="JSON.stringify(textMessages)"
    @send-message="sendMessage($event.detail[0])"
    @fetch-messages="fetchMessages($event.detail[0])"
  />
</template>

<script>
import { register } from 'vue-advanced-chat'
import moment from 'moment'
import { displayErrors } from '@/utils/helpers'

register()
export default {
  data () {
    return {
      connectionErrorMessage: 'Wystąpił bląd z połączeniem. Spróbuj odświeżyć strone.',
      connection: null,
      currentUserId: 'Admin',
      rooms: [],
      messages: [],
      messagesLoaded: false,
      currentRoom: null,
      textMessages: {
        ROOMS_EMPTY: 'Brak rozmów',
        ROOM_EMPTY: 'Nie wybrano rozmowy',
        NEW_MESSAGES: 'Nowa wiadomość',
        MESSAGE_DELETED: 'Ta wiadomość została usunięta',
        MESSAGES_EMPTY: 'Brak wiadomości',
        CONVERSATION_STARTED: 'Rozpoczęcie rozmowy:',
        TYPE_MESSAGE: 'Nowa wiadomość',
        SEARCH: 'Szukaj',
        IS_ONLINE: 'online',
        LAST_SEEN: 'last seen ',
        IS_TYPING: 'is writing...',
        CANCEL_SELECT_MESSAGE: 'Cancel'
      }
    }
  },
  async mounted () {
    this.connectionSocket(this)
  },
  beforeDestroy () {
    this.disconnectSocket()
  },
  methods: {
    disconnectSocket () {
      this.connection.close(1000, 'Closing from client')
      this.connection = null
    },
    fetchMessages ({ options = {}, room = {} }) {
      var newMessage = {
        type: 'GetMessageFromRoom',
        roomId: room.roomId,
        senderId: this.currentUserId,
        message: null
      }
      if (this.messages.length === 0) {
        this.currentRoom = room.roomId
        this.connection.send(JSON.stringify(newMessage))
      } else if (this.currentRoom !== room.roomId) {
        this.currentRoom = room.roomId
        this.messages = []
        this.connection.send(JSON.stringify(newMessage))
      }
      this.messagesLoaded = true
    },
    sendMessage (message) {
      if (this.connection.readyState === 2 || this.connection.readyState === 3) {
        displayErrors(this.connectionErrorMessage)
        return
      }

      const { date, timestamp } = this.formatDate(moment.utc())

      this.messages = [
        ...this.messages,
        {
          _id: '',
          roomId: message.roomId,
          content: message.content,
          senderId: this.currentUserId,
          timestamp: timestamp,
          date: date
        }
      ]
      var newMessage = {
        type: 'SENDMESSAGETOROOM',
        roomId: message.roomId,
        senderId: this.currentUserId,
        message: {
          messageId: '',
          roomId: message.roomId,
          senderId: this.currentUserId,
          content: message.content,
          dateCreate: date,
          status: '2'
        }
      }

      this.connection.send(JSON.stringify(newMessage))

      this.reorderRooms(0, message.roomId)
    },
    reorderRooms (count, roomId) {
      const roomIndex = this.rooms.findIndex(obj => obj.roomId === roomId)

      if (!roomIndex || roomIndex < 0) {
        this.connection.send(JSON.stringify({ type: 'GETROOMS', roomId: null, chatRooms: [] }))
      } else {
        const room = this.rooms.splice(roomIndex, 1)
        room[0].unreadCount = count > 0 ? ' ' : 0

        this.rooms = [
          room[0],
          ...this.rooms
        ]
      }
    },
    formatDate (utc) {
      const dateTime = moment.utc(utc).locale('pl').local()
      const date = dateTime.format('L')
      const timestamp = dateTime.format('LT')

      return {
        date, timestamp
      }
    },
    connectionSocket: function (app) {
      this.connection = new WebSocket(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${process.env.VUE_APP_BASE_WS}/ws?token=${localStorage.getItem('token')}`)

      this.connection.onerror = function () {
        displayErrors(app.connectionErrorMessage)
      }

      this.connection.onmessage = function (event) {
        var receivedMessage = JSON.parse(event.data)
        if (receivedMessage.type === 'GETMESSAGEFROMROOM') {
          if (receivedMessage.chatMessages.length === 0) {
            this.messagesLoaded = false
          } else {
            var objIndex = app.rooms.findIndex(obj => obj.roomId === receivedMessage.roomId)
            app.rooms[objIndex].unreadCount = 0
            for (let i = 0; i < receivedMessage.chatMessages.length; i++) {
              const { date, timestamp } = app.formatDate(receivedMessage.chatMessages[i].dateCreate)
              app.messages = [
                ...app.messages,
                {
                  roomId: receivedMessage.roomId,
                  _id: receivedMessage.chatMessages[i].messageId,
                  content: receivedMessage.chatMessages[i].content,
                  senderId: receivedMessage.chatMessages[i].senderId,
                  timestamp: timestamp,
                  date: date
                }
              ]
            }
          }
        } else if (receivedMessage.type === 'GETROOMS') {
          app.rooms = []
          for (let i = 0; i < receivedMessage.chatRooms.length; i++) {
            var usersRoom = []
            for (let j = 0; j < receivedMessage.chatRooms[i].chatUsers.length; j++) {
              usersRoom.push({
                _id: receivedMessage.chatRooms[i].chatUsers[j].id,
                username: receivedMessage.chatRooms[i].chatUsers[j].userName
              })
            }
            app.rooms.push({
              roomId: receivedMessage.chatRooms[i].roomId,
              roomName: receivedMessage.chatRooms[i].roomName,
              unreadCount: receivedMessage.chatRooms[i].unreadCount > 0 ? ' ' : 0,
              users: usersRoom
            })
          }
        } else if (receivedMessage.type === 'SENDMESSAGETOROOM') {
          if (receivedMessage.roomId === app.currentRoom) {
            const { date, timestamp } = app.formatDate(receivedMessage.message.dateCreate)
            app.messages = [
              ...app.messages,
              {
                roomId: receivedMessage.roomId,
                _id: receivedMessage.message.messageId,
                content: receivedMessage.message.content,
                senderId: receivedMessage.message.senderId,
                timestamp: timestamp,
                date: date
              }
            ]

            app.reorderRooms(receivedMessage.count, receivedMessage.roomId)
          }
        } else if (receivedMessage.type === 'NEWROOM') {
          var userNewRoom = []
          for (let j = 0; j < receivedMessage.chatRoom.chatUsers.length; j++) {
            userNewRoom.push({
              _id: receivedMessage.chatRoom.chatUsers[j].Id,
              username: receivedMessage.chatRoom.chatUsers[j].userName
            })
          }

          if (app.rooms.some(x => x.roomId === receivedMessage.chatRoom.roomId)) {
            return
          }
          app.rooms = [
            {
              roomId: receivedMessage.chatRoom.roomId,
              roomName: receivedMessage.chatRoom.roomName,
              users: userNewRoom,
              unreadCount: receivedMessage.count > 0 ? ' ' : 0
            },
            ...app.rooms
          ]
        } else if (receivedMessage.type === 'SENDUNREADMESSAGE') {
          if (app.$data.currentRoom === receivedMessage.roomId && receivedMessage.message) {
            const { date, timestamp } = app.formatDate(receivedMessage.message.dateCreate)
            app.messages = [
              ...app.messages,
              {
                roomId: receivedMessage.roomId,
                _id: receivedMessage.message.messageId,
                content: receivedMessage.message.content,
                senderId: receivedMessage.message.senderId,
                timestamp: timestamp,
                date: date
              }
            ]
          }

          app.reorderRooms(receivedMessage.count, receivedMessage.roomId)
        }
      }

      this.connection.onopen = function (event) {
        console.log('Successfully connected to the echo websocket server...')
      }
    }
  }
}
</script>
